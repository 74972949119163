@use "../../resources/scss/themes/themes";
@use "../../resources/scss/utils/mixins";
@use "../../resources/scss/utils/functions";
@use "../../resources/scss/themes/dark-theme";
@use "../../resources/scss/themes/base-colors";
@use "sass:list";
@use "sass:math";
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";


@mixin desktopHover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin makeGradient($name) {
  $list: ();

  $split-values: functions.str-split($name, "-");
  $currentStep: 0;
  $step: math.div(100, length($split-values));
  @each $value in $split-values {
    $start: "#{$currentStep}%";
    $currentStep: $currentStep+$step;
    $stop: "#{$currentStep}%";
    $list: append($list, "#{themes.themed(#{$value}500)} #{$start} #{$stop}", comma);

  }
  &.color-#{$name} {
    //> path {
    //  stroke: url(#color-#{$name});
    //  stroke-opacity: 0.5;
    //  fill: url(#color-#{$name});
    //}

    .text {
      line-height: 1;

      $len: list.length($list);
      @if $len == 1 {
        color: themes.themed(#{$name}500)
      } @else {
        background: -webkit-linear-gradient(#{$list});
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

@mixin style($textColor, $extraTextContent...) {

  > path,
  circle {
    @content;
  }

  .text {

    color: $textColor;

    @each $name,
    $value in keywords($extraTextContent) {
      #{$name}: $value;
    }
  }
}

@mixin styleSector($colorName) {
  $color: themes.themed($colorName);

  @include style(themes.textContrast($color)) {
    fill: $color;

    @include desktopHover {
      fill: darken($color, 5);
    }
  }

  &.disabled {
    @include style(themes.textContrast(mix($color, themes.themed("disabled400"), 5%))) {
      fill: mix($color, themes.themed("disabled400"), 30%);
      stroke: mix($color, themes.themed("disabled200"), 30%);
    }
  }
}


@mixin styleItem($class, $background, $stroke: none) {
  @include themes.themify(themes.$themes) {
    $color: themes.themed($background);
    $textColor: themes.textContrast($color);

    .#{$class} {
      fill: $color;

      @if $stroke ==small {
        stroke-width: 0;
        stroke: none;
      } @else {
        stroke-width: 0.5px;
        stroke: themes.themed($stroke)
      }

      //transition: fill 200ms ease-in-out;
      cursor: pointer;

      //&:not(.sectors-item) {
      & ~ text {
        fill: $textColor;
      }

      //&:hover {
      //  fill: mix($color, themes.textContrast(themes.themed("background50")), 75%);
      //}
    }
  }
}

.farm-map-component {

  #response-container {
    position: absolute;
    right: 0;
    z-index: 1;
    pointer-events: none;
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {

    circle,
    path,
    rect {
      shape-rendering: optimizeSpeed;
    }

    svg {
      contain: paint;
    }
  }


  &.chrome {
    .text-rich {
      i {
        opacity: 0.5; // safari cannot into rotate with opacity on so we will just stick to chrome here
      }
    }

    .text-rich-horizontal {
      > div {
        position: relative;
      }
    }
  }

  &:not(.chrome) {
    .text-rich-horizontal {
      position: unset;

      &:nth-child(even) {
        &:not(:empty):not(:last-child) {
          &:before {
            content: unset;
          }

          padding-right: 1px;
          border-right: 1px solid currentColor;
        }
      }
    }
  }

  .fast-action-panel {
    position: relative;

    @include themes.themify(themes.$themes) {
      border-top: 1px dashed #{themes.textContrast(themes.themed("background200"))}AF;
    }

    padding-top: 1rem;
    margin-bottom: 1.4rem;

    .title {
      position: absolute;
      right: 0;
      top: 0;
      text-transform: uppercase;
      pointer-events: none;
      font-size: 1.2rem;
      opacity: 0.5;
    }

    @include mixins.breakpoint(themes.$md) {
      padding-top: 0;

      .title {
        font-size: 0.8rem;
        position: relative;
        transform: translateY(-0.8rem);
        display: inline-block;
        opacity: 1;
        border-radius: 5px;
        padding: 2px;

        @include themes.themify(themes.$themes) {
          background: themes.themed("background200");
          border: 1px dashed #{themes.textContrast(themes.themed("background200"))}AF;
        }
      }
    }
  }

  user-select: none;


  .text {
    @include themes.themify(themes.$themes) {
      color: themes.textContrast(themes.themed("background200"));
    }
  }


  .text-rich {
    width: 100%;

    > div {
      white-space: nowrap;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      margin-right: 1px;
    }

  }

  .text-rich-vertical {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .text-rich-horizontal {

    display: grid !important;
    grid-auto-columns: 1fr;
    grid-template-columns: 50% 50%;
    gap: 1px 4px;


    > div {

      &:nth-child(odd) {
        text-align: left;
      }

      &:nth-child(even) {
        text-align: right;

        &:not(:empty):not(:last-child) {
          &:before {
            content: " ";
            opacity: 0.5;
            background: currentColor;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -2px;
          }
        }
      }

      &:first-child {
        text-align: center;
      }

      &:nth-child(1) {
        grid-column: 1/3;
      }
    }
  }

  .text-rich-horizontal-long {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    > div {
      margin-right: 2px;
    }
  }


  &.mobile {

    #response-container {
      bottom: 0;
    }

    .ctrl-container {
      top: 0.5rem;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      z-index: 1;
      pointer-events: none;

      > div {
        pointer-events: all;
      }


      .s-box {
        margin-left: 4rem;

        .fetura-button {
          width: 2.4rem !important;
          height: 2.4rem !important;
          @include mixins.shadow();
        }

        .fetura-input {
          border-radius: 1rem !important;
          @include mixins.shadow();
        }
      }

      .t-box,
      .l-box {
        align-self: flex-end;

        .fetura-button {
          @include mixins.shadow();
        }
      }

      @media screen and (orientation: landscape) {
        width: calc(100% - 200px);
        flex-direction: row;
        gap: 1rem;
        .fetura-input, .fetura-button {
          margin: 0 !important;
        }
        .fetura-button {
          width: 2.4rem !important;
          height: 2.4rem !important;
        }
      }
    }


  }


  .view-portal {
    > .svg-container {
      position: absolute;
      transition: none;
      transform-style: flat;


      .map-wrapper {
        transition: none;
      }
    }
  }

  &.moving {
    .svg-container {
      will-change: transform;
    }
  }

  &.animate {
    .view-portal {
      > .svg-container {
        transition: transform 1000ms ease-in-out;

      }
    }
  }

  .progress {
    position: absolute;
    width: 100%;
    height: 5px;
    z-index: 999;
    pointer-events: none;

    &.done {
      background: transparent !important;

      * {
        background: transparent !important;
      }
    }
  }

  .loading {
    opacity: 0.5;
  }

  #area-canvas {
    position: absolute;
    display: none;
  }

  .portal-container {
    position: relative;
    overflow: hidden;

    .map-drawer-mobile {
      position: absolute;
      bottom: 0;
      left: .75rem;
      right: .75rem;
      touch-action: none;
    }

    .map-drawer {
      z-index: 2;
      overflow: hidden;
      max-height: 100%;
      height: 300px;
      position: relative;
      pointer-events: none;

      .map-drawer-content {
        pointer-events: auto;
        overflow: auto;
        max-height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }

        padding-bottom: 3rem;
      }

      .toggle-expand {
        pointer-events: auto;
      }

    }

    @include mixins.breakpointMinWithHelper(themes.$sm) {
      .map-drawer {
        z-index: 3;
        position: absolute;
        border-radius: themes.$paddingSmallVertical;
        top: .75rem;
        right: .75rem;
        min-width: 500px;
        width: 500px;
        max-width: 100%;
        height: 0;
        min-height: 65vh;
        max-height: 65vh;

        overflow: visible !important;

        .map-drawer-content {
          @include themes.themify(themes.$themes) {
            //background: transparent;
            background: themes.themed("background50");
            color: themes.textContrast(themes.themed("background50"));
            border: 1px solid themes.themed("background300");
          }

          border-radius: 5px;
          @include mixins.shadow();
          padding: 1rem;
        }

        transition: transform 500ms ease-in-out;

        .toggle-expand {
          position: absolute;
          text-align: center;
          cursor: pointer;
          writing-mode: vertical-lr;
          left: -3rem;
          top: 1.5rem;
          height: auto;
          width: 3rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: 1rem;
          padding-bottom: 1rem;

          @include themes.themify(themes.$themes) {
            background: themes.themed("info500");
            color: themes.textContrast(themes.themed("info500"));
            @include mixins.shadow();
          }

          border-radius: 0.5rem 0 0 0.5rem;

          &:before {
            content: attr(aria-label);
            display: inline-block;
            text-transform: lowercase;
            width: 100%;
            line-height: 3rem;
          }

          &:after {
            display: inline-block;
            margin-top: 0.5rem;
            font: bold normal normal 1rem "Font Awesome 6 Pro";
          }
        }

        &.expanded {
          transform: translateX(0);

          .toggle-expand {
            &:after {
              content: fa-content($fa-var-arrow-right);
            }
          }
        }

        &:not(.expanded) {
          transform: translateX(calc(100% + .75rem));

          .toggle-expand {
            &:after {
              content: fa-content($fa-var-arrow-left);
            }
          }
        }
      }
    }

    @include mixins.breakpointWithHelper(themes.$sm) {
      .map-drawer {
        padding: 1rem;
        position: absolute;
        border-radius: themes.$paddingBigVertical themes.$paddingBigVertical 0 0;
        height: calc(100% - 1rem);
        width: calc(100% - 1rem);
        transition: bottom 500ms ease-in-out;
        left: 0.5rem;
        $smallFont: 0.8rem;

        @include themes.themify(themes.$themes) {
          background: themes.themed("background50");
          border: 1px solid themes.themed("background300");
        }

        .toggle-expand {
          text-align: center;
          cursor: pointer;
          display: block;

          &:before {
            content: attr(aria-label);
            text-transform: lowercase;
            display: inline-block;
            height: 3rem;
          }

          &:after {
            display: inline-block;
            margin-left: 0.5rem;
            font: bold normal normal 1rem "Font Awesome 6 Pro";
          }
        }

        &.expanded {
          bottom: 0;

          .toggle-expand {
            &:after {
              content: fa-content($fa-var-arrow-down);
            }
          }
        }

        &:not(.expanded) {
          bottom: calc(-100% + 5rem);

          .toggle-expand {
            &:after {
              content: fa-content($fa-var-arrow-up);
            }
          }
        }
      }
    }
  }

  .view-portal {
    height: 650px;
    overflow: hidden;
    position: relative;

    .map-wall {
      stroke-width: 0 !important;
      pointer-events: none;
    }

    .map-message {
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 2;
      position: absolute;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;

      @include themes.themify(themes.$themes) {
        $bgLight: #{themes.themed("background600")}1D;
        $bgDark: #{themes.themed("background900")}1D;
        background: repeating-linear-gradient(45deg,
                $bgLight,
                $bgLight 10px,
                $bgDark 10px,
                $bgDark 20px);
      }

    }

    > .svg-container {
      transform-origin: top left;
      overflow: visible !important;

      foreignObject {
        pointer-events: none;

        > div {
          pointer-events: none;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .map-selected-item {
        pointer-events: none;
        fill: transparent;

        &.fill {
          @include themes.themify(themes.$themes) {
            fill: themes.themed("info500");
            mix-blend-mode: color;
            opacity: 0.3;
          }
        }

        &.outline {
          @include themes.themify(themes.$themes) {
            //stroke-width: 1px;
            stroke: themes.themed("info500");
          }
        }

        &.success {
          @include themes.themify(themes.$themes) {
            @include style(themes.textContrast(mix(themes.themed("success500"), themes.themed("background300"), 15%))) {
              fill: mix(themes.themed("success500"), themes.themed("background300"), 15%);
              stroke: themes.themed("success500");
            }
          }
        }
      }

      .map-light {
        fill: transparent;
        stroke-width: 1px;

        @include themes.themify(themes.$themes) {
          stroke: #{themes.textContrast(themes.themed("background200"))}2F;
        }

        .text {
          opacity: 0.25;
        }
      }

      .map-light--small {
        stroke-width: 0;
      }

      .map-light--on {
        path:not([fill]) {
          fill: url(#farm-map-light);
          opacity: 0.5;
        }
      }

      .map-chamber,
      .map-sector,
      .map-building,
      .map-device {
        @include themes.themify(themes.$themes) {
          @include style(themes.textContrast(themes.themed("background300"))) {
            stroke-width: 2px;
            fill: themes.themed("background300");
            stroke: themes.themed("background50");
            cursor: pointer;
          }

          &.error {
            @include style(themes.textContrast(themes.themed("error500"))) {
              fill: themes.themed("error500");
              stroke: themes.themed("error500");
            }
          }

          &.info {
            @include style(themes.textContrast(themes.themed("info500"))) {
              fill: themes.themed("info500");
              stroke: themes.themed("info500");
            }
          }

          &.inactive {
            @include style(themes.textContrast(themes.themed("disabled500"))) {
              fill: themes.themed("disabled500");
              stroke: themes.themed("disabled500");
            }
          }

          &.warning {
            @include style(themes.textContrast(themes.themed("warning500"))) {
              fill: themes.themed("warning500");
              stroke: themes.themed("warning500");
            }
          }

          &.success {
            @include style(themes.textContrast(themes.themed("success500"))) {
              fill: themes.themed("success500");
              stroke: themes.themed("success500");
            }
          }

          &.error-children {
            @include style(themes.textContrast(mix(themes.themed("error500"), themes.themed("background300"), 15%))) {
              fill: mix(themes.themed("error500"), themes.themed("background300"), 15%);
              stroke: themes.themed("error500");
            }
          }

          &.info-children {
            @include style(themes.textContrast(mix(themes.themed("info500"), themes.themed("background300"), 15%))) {
              fill: mix(themes.themed("info500"), themes.themed("background300"), 5%);
              stroke: mix(themes.themed("info500"), themes.themed("background300"), 5%);
            }
          }

          &.warning-children {
            @include style(themes.textContrast(mix(themes.themed("warning500"), themes.themed("background300"), 15%))) {
              fill: mix(themes.themed("warning500"), themes.themed("background300"), 15%);
              stroke: themes.themed("warning500");
            }
          }

          &.disabled {
            @include style(themes.themed("disable200"), $opacity: 0.5) {
              cursor: not-allowed;
              fill: themes.themed("disabled400");
              stroke: themes.themed("disabled200");
              opacity: 0.25;
            }
          }
        }

      }

      .map-chamber {
        @include themes.themify(themes.$themes) {
          @include makeGradient("error-info-success-warning");
          @include makeGradient("error-info-success");
          @include makeGradient("error-info-warning");
          @include makeGradient("error-info");
          @include makeGradient("error-success-warning");
          @include makeGradient("error-success");
          @include makeGradient("error-warning");
          @include makeGradient("error");
          @include makeGradient("info-success-warning");
          @include makeGradient("info-success");
          @include makeGradient("info-warning");
          @include makeGradient("info");
          @include makeGradient("success-warning");
          @include makeGradient("success");
          @include makeGradient("warning");
        }
      }

      .map-sector {

        > path,
        circle {
          stroke-width: 0 !important;
        }

        @include themes.themify(themes.$themes) {
          &.sector-type-1 {
            //MATING: 1
            @include styleSector("mating");
          }

          &.sector-type-2 {
            //SOWS: 2
            @include styleSector("sows");
          }

          &.sector-type-3 {
            //RENOVATION_SOWS: 3
            @include styleSector("other");
          }

          &.sector-type-4 {
            //DELIVERY: 4
            @include styleSector("birth");
          }

          &.sector-type-5 {
            //BABY_ROOM: 5
            @include styleSector("other");
          }

          &.sector-type-6 {
            //PORK_HOUSE: 6
            @include styleSector("porkers");
          }

          &.sector-type-7 {
            //PIGLET_HOUSE: 7
            @include styleSector("piglets");
          }

          &.sector-type-8 {
            //BOAR_HOUSE 8
            @include styleSector("other");
          }
        }
      }

      .map-standing {
        .with-boxes {
          @include themes.themify(themes.$themes) {
            @include style(themes.textContrast(themes.themed("background50"))) {
              stroke-width: 0.5px;
              stroke: themes.themed("background50");
            }
          }
        }
      }
    }


  }
}


.map-drawer {
  background: transparent;


  .toggle-expand {
    display: none;
  }


}


.map-drawer-content {
  .map-drawer-header {
    > div {
      &:first-child {
        font-size: 1.2rem;
        font-weight: bolder;
      }
    }

    margin-bottom: 2rem;
  }

  .map-drawer-animals-counter {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5rem;
      border-radius: 0.5rem;

      span {
        font-weight: 500;
      }
    }

    &-location {
      display: flex;
      width: -webkit-fill-available;
      justify-content: center;

      &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        @include themes.themify(themes.$themes) {
          color: themes.textContrast(themes.themed("background50"));
          border-right: 1px solid mix(themes.themed("background50"), themes.textContrast(themes.themed("background50")), 75%);
        }

        .pieces {
          font-weight: 500;
        }
      }

      &-container:last-child {
        @include themes.themify(themes.$themes) {
          border-right: 0;
        }
      }
    }
  }

  .map-drawer-inventory,
  .map-drawer-params,
  .map-drawer-alerts,
  .map-drawer-warnings {
    &:empty {
      display: none;
    }

    &:not(:empty) {
      &:not(:last-child) {
        margin-top: 0.5rem;
        padding-bottom: 1rem;

        @include themes.themify(themes.$themes) {
          border-bottom: 1px solid themes.themed("background300")
        }
      }

      position: relative;

      &:before {
        content: attr(aria-label);
        text-transform: lowercase;
        display: block;
        opacity: 0.75;
        margin-bottom: 0.2rem;
        font-style: italic;
      }
    }

    &:empty {
      border-bottom: none;
    }
  }

  .map-drawer-alerts,
  .map-drawer-warnings {
    li {
      > small {
        margin-left: 0.25rem;
        border-radius: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-weight: bold;
      }

      &.header {
        &:before {
          content: none !important;
        }

        text-transform: lowercase;
        font-style: italic;
        opacity: 0.5;
        margin-left: -1.25rem;

        &:after {
          content: ":";
        }
      }
    }

    ul {
      list-style: none;
    }

    ul li::before {
      @include themes.themify(themes.$themes) {
        color: themes.themed("error500");
      }

      content: fa-content($fa-var-exclamation-circle);
      font: bold normal normal 1rem "Font Awesome 6 Pro";
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
      margin-right: 0.25rem;
    }
  }

  .map-drawer-alerts {
    li {
      > small {
        @include themes.themify(themes.$themes) {
          color: themes.themed("error400");
          background: themes.themed("error100");
        }
      }
    }

    ul li::before {
      @include themes.themify(themes.$themes) {
        color: themes.themed("error500");
      }

      content: fa-content($fa-var-exclamation-triangle);
    }
  }

  .map-drawer-warnings {
    li {
      > small {
        @include themes.themify(themes.$themes) {
          color: themes.themed("warning400");
          background: themes.themed("warning100");
        }
      }
    }

    ul li::before {
      @include themes.themify(themes.$themes) {
        color: themes.themed("warning500");
      }

      content: fa-content($fa-var-exclamation-triangle);
    }
  }


  .map-drawer-params {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: min-content;

    &:before {
      grid-column: span 2;
    }

    .exclamation {
      text-align: center;
      display: inline-block;
    }

    .value {
      font-weight: bolder;
      display: inline-block;
      text-align: center;
    }

    .label {
      font-size: 0.8rem;
      text-transform: lowercase;
    }

    > div {
      margin: themes.$paddingBigVertical * 0.5;
      padding: themes.$paddingBigVertical * 0.5;
      border-radius: themes.$paddingSmallVertical;

      @include themes.themify(themes.$themes) {
        background: mix(themes.themed("background100"), themes.themed("background300"), 75%);
      }

      &.error {
        @include themes.themify(themes.$themes) {
          background: mix(themes.themed("background50"), themes.themed("error500"), 75%);
          color: themes.textContrast(mix(themes.themed("background50"), themes.themed("error500"), 75%));
        }
      }

      &.warning {
        @include themes.themify(themes.$themes) {
          background: mix(themes.themed("background50"), themes.themed("warning500"), 75%);
          color: themes.textContrast(mix(themes.themed("background50"), themes.themed("warning500"), 75%));
        }
      }

      text-align: center;
    }
  }

  @include mixins.breakpointWithHelper(themes.$xs) {
    .map-drawer-params {
      grid-template-columns: 100%;
    }
  }

  .map-drawer-inventory {
    .fetura-chip {
      display: inline-block !important;
      margin-bottom: 0.5rem !important;

      .fetura-chip-content {
        > div {
          &:first-child {
          }

          &:last-child {
            opacity: 0.75;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

}

@mixin textFeed($color, $icon) {
  fill: $color;
}

@keyframes diode-blink {
  0% {
    opacity: .1;
  }

  20% {
    opacity: .9;
  }

  100% {
    opacity: .1;
  }
}

.theme-dark {
  .feed-state {
    &.feed {
      &-0 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "error500"), fa-content($fa-var-frown));
      }

      &-1 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "warning500"), fa-content($fa-var-meh));
      }

      &-2 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "success500"), fa-content($fa-var-smile));
      }

      &-3 {
        @include textFeed(map-get(base-colors.$baseColors, "white500"), fa-content($fa-var-grin));
      }
    }
  }
}

.led-diode {
  pointer-events: none;

  &.blink {
    animation: diode-blink 1000ms infinite both;
  }
}

.theme-normal {


  .feed-state {
    &.feed {
      &-0 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "error500"), fa-content($fa-var-frown));
      }

      &-1 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "warning500"), fa-content($fa-var-meh));
      }

      &-2 {
        @include textFeed(map-get(dark-theme.$darkColorTheme, "success500"), fa-content($fa-var-smile));
      }

      &-3 {
        @include textFeed(map-get(base-colors.$baseColors, "white500"), fa-content($fa-var-grin));
      }

      &-3 {
        @include textFeed(map-get(base-colors.$baseColors, "white500"), fa-content($fa-var-grin));
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.s-renderer {
  animation: fadeIn 1000ms;
}